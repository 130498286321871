import React from "react";
import styles from "./App.module.scss";
import { HomePage } from "./component/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import { TermsOfUsePage } from "./component/HomePage/TermsOfUsePage/TermsOfUsePage";
import { PrivacyPolicyPage } from "./component/HomePage/PrivacyPolicyPage/PrivacyPolicyPage";
import { RestaurantsPage } from "./component/RestaurantsPage/RestaurantsPage";

function App() {
  return (
    <div className={styles.App}>
      <Routes>
        <Route path={"/"} element={<HomePage />} />
        <Route path={"/privacy-policy"} element={<PrivacyPolicyPage />} />
        <Route path={"/terms-of-use"} element={<TermsOfUsePage />} />
        <Route
          path={"/restaurant/:restaurantsId/:riddleId"}
          element={<RestaurantsPage />}
        />
      </Routes>
    </div>
  );
}

export default App;
